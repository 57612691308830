import React, { useEffect, useState, useRef } from "react"; 
import {
  getQueryLogs,
  getQueryLogsByBotUserId,
  getQueryLogsByClassId,
  getAllChatbots,
} from "../apis"; 
import { Button, Input } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faDownload } from "@fortawesome/free-solid-svg-icons";

const ChatbotLogs = () => {
  const [inputSessionId, setInputSessionId] = useState("");
  const [inputBotUserId, setInputBotUserId] = useState("");
  const [inputClassId, setInputClassId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [botUserMap, setBotUserMap] = useState({});
  const bottomRef = useRef(null);

  // Fetch all chatbots and map bot_id to user email
  const fetchBotUserMapping = async () => {
    try {
      const response = await getAllChatbots();
      const bots = response.data;
      const botMapping = {};
      bots.forEach((bot) => {
        const userEmail =
          bot.user_email ||
          (bot.org_id && bot.org_id.email) ||
          (bot.app_id && bot.app_id.email) ||
          bot.user_id;
        botMapping[bot._id] = userEmail || bot._id;
      });
      setBotUserMap(botMapping);
    } catch (error) {
      console.error("Error fetching bot-user mapping:", error);
    }
  };

  useEffect(() => {
    fetchBotUserMapping(); // Fetch bot-user mapping on component load
  }, []);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [logs]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      fetchLogs();
    }
  };

  const fetchLogs = async () => {
    // Prioritize class_id search if provided
    if (inputClassId) {
      setSessionId("");
      setLogs([]);
      setIsLoading(true);

      try {
        const response = await getQueryLogsByClassId(inputClassId);
        const botsData = response.data.bots || [];

        // Group logs by session_id similarly as done for sessionId search
        const logsGroupedBySession = {};
        botsData.forEach((bot) => {
          const currentSessionId = bot.session_id || "unknown_session";
          if (!logsGroupedBySession[currentSessionId]) {
            logsGroupedBySession[currentSessionId] = {
              session_id: currentSessionId,
              bots: {},
            };
          }

          if (!logsGroupedBySession[currentSessionId].bots[bot.bot_id]) {
            logsGroupedBySession[currentSessionId].bots[bot.bot_id] = {
              bot_id: bot.bot_id,
              chats: [],
            };
          }

          logsGroupedBySession[currentSessionId].bots[bot.bot_id].chats.push(...bot.chats);
        });

        const logsArray = Object.values(logsGroupedBySession).map((session) => ({
          session_id: session.session_id,
          bots: Object.values(session.bots),
        }));

        setLogs(logsArray);
      } catch (error) {
        console.error("Error fetching logs by class_id:", error);
        setLogs([]);
      }
      setIsLoading(false);

    } else if (inputSessionId) {
      setSessionId(inputSessionId);
      setLogs([]);
      setIsLoading(true);

      try {
        const response = await getQueryLogs(inputSessionId);
        const botsData = response.data.bots || [];

        // Group logs by session_id
        const logsGroupedBySession = {};
        botsData.forEach((bot) => {
          if (!logsGroupedBySession[inputSessionId]) {
            logsGroupedBySession[inputSessionId] = {
              session_id: inputSessionId,
              bots: {},
            };
          }

          // If bot_id doesn't exist in the session yet, initialize it
          if (!logsGroupedBySession[inputSessionId].bots[bot.bot_id]) {
            logsGroupedBySession[inputSessionId].bots[bot.bot_id] = {
              bot_id: bot.bot_id,
              chats: [],
            };
          }

          // Append chats to the corresponding bot
          logsGroupedBySession[inputSessionId].bots[bot.bot_id].chats.push(
            ...bot.chats
          );
        });

        // Convert the logs object back to an array for easier rendering
        const logsArray = Object.values(logsGroupedBySession).map((session) => ({
          session_id: session.session_id,
          bots: Object.values(session.bots),
        }));

        setLogs(logsArray);
      } catch (error) {
        console.error("Error fetching logs:", error);
        setLogs([]);
      }
      setIsLoading(false);
    } else if (inputBotUserId) {
      setSessionId("");
      setLogs([]);
      setIsLoading(true);

      try {
        const response = await getQueryLogsByBotUserId(inputBotUserId);
        const sessionsData = response.data.sessions || [];
        setLogs(sessionsData);
      } catch (error) {
        console.error("Error fetching logs:", error);
        setLogs([]);
      }
      setIsLoading(false);
    } else {
      console.error("No input provided.");
    }
  };

  const getTrimmedEmail = (emailOrId) => {
    if (!emailOrId) return "";
    if (emailOrId.includes("@")) {
      return emailOrId.split("@")[0];
    } else {
      return emailOrId;
    }
  };

  // Helper function to convert logs into CSV format
  const convertLogsToCSV = (logsData) => {
    // Define CSV headers
    const headers = ["SessionID", "BotID", "BotUser", "Question", "Solution"];
    const rows = [headers.join(",")];

    logsData.forEach((session) => {
      const { session_id, bots } = session;
      bots.forEach((bot) => {
        const { bot_id, chats } = bot;
        const botUser = botUserMap[bot_id] || bot_id;
        chats.forEach((chat) => {
          const row = [
            `"${session_id}"`,
            `"${bot_id}"`,
            `"${botUser}"`,
            `"${(chat.question || "").replace(/"/g, '""')}"`,
            `"${(chat.solution || "").replace(/"/g, '""')}"`
          ].join(",");
          rows.push(row);
        });
      });
    });

    return rows.join("\n");
  };

  const handleDownloadCSV = () => {
    if (!logs || logs.length === 0) {
      alert("No logs to download.");
      return;
    }

    const csvContent = convertLogsToCSV(logs);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `chatbot_logs_${Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the object URL after download
    URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white p-6 rounded-xl shadow-md w-full mx-auto max-w-7xl">
      <div className="mb-6 flex flex-col md:flex-row justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-800">Chatbot Logs</h1>
        
        {logs.length > 0 && (
          <Button
            variant="filled"
            className="bg-green-500 hover:bg-green-700 text-white py-3 rounded-lg flex items-center justify-center"
            onClick={handleDownloadCSV}
          >
            <FontAwesomeIcon icon={faDownload} className="text-lg mr-2" />
            Download CSV
          </Button>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <Input
          type="text"
          label="Enter Session ID"
          value={inputSessionId}
          onChange={(e) => setInputSessionId(e.target.value)}
          onKeyDown={handleKeyDown}
          className="w-full"
        />
        <Input
          type="text"
          label="Enter Bot's User ID (Email)"
          value={inputBotUserId}
          onChange={(e) => setInputBotUserId(e.target.value)}
          onKeyDown={handleKeyDown}
          className="w-full"
        />
        <Input
          type="text"
          label="Enter Class ID"
          value={inputClassId}
          onChange={(e) => setInputClassId(e.target.value)}
          onKeyDown={handleKeyDown}
          className="w-full"
        />
      </div>
      <div className="flex justify-center mb-6">
        <Button
          variant="filled"
          className="w-full bg-blue-500 hover:bg-blue-700 text-white py-3 rounded-lg flex items-center justify-center"
          onClick={fetchLogs}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="text-lg mr-2" />
          Fetch Logs
        </Button>
      </div>

      {isLoading ? (
        <p className="text-center text-gray-500">Loading...</p>
      ) : (
        <div className="h-[40vh] md:h-[60vh] overflow-y-scroll bg-gray-100 rounded-lg p-4">
          {logs.length > 0 ? (
            logs.map((sessionData, sessionIndex) => (
              <div key={sessionIndex}>
                <p className="text-center mb-4 text-sm text-gray-500">
                  <strong>Session ID:</strong> {sessionData.session_id}
                </p>
                {sessionData.bots.map((botData, botIndex) => (
                  <div key={botIndex}>
                    <p className="text-center mb-4 text-sm text-gray-500">
                      <strong>Bot:</strong>{" "}
                      {botUserMap[botData.bot_id] || botData.bot_id}
                    </p>
                    {botData.chats.map((query, index) => {
                      const botName = getTrimmedEmail(
                        botUserMap[botData.bot_id] || botData.bot_id
                      );

                      return (
                        <div key={index} className="mb-4">
                          <div className="flex items-start space-x-2">
                            <div className="flex-shrink-0 font-bold text-blue-500">
                              {getTrimmedEmail(sessionData.session_id)}:
                            </div>
                            <div className="bg-white p-2 rounded-md shadow-sm flex-grow">
                              {query.question}
                            </div>
                          </div>
                          <div className="flex items-start space-x-2 mt-2">
                            <div className="flex-shrink-0 font-bold text-green-500">
                              {botName}:
                            </div>
                            <div className="bg-white p-2 rounded-md shadow-sm flex-grow">
                              {query.solution}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">No chats found.</p>
          )}
          <div ref={bottomRef} />
        </div>
      )}
    </div>
  );
};

export default ChatbotLogs;
