import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faMicrophone,
  faMicrophoneSlash,
  faForwardStep
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import SpeakerOnIcon from "./icons/SpeakerOnIcon";
import SpeakerOffIcon from "./icons/SpeakerOffIcon";
import ChatHistoryIcon from "./icons/ChatHistoryIcon";
import SendChatIcon from "./icons/SendChatIcon";
import CaptionIcon from "./icons/CaptionIcon";

function LMSStyle({
  char,
  handleStopTTS,
  displayedText,
  initialMessage,
  question,
  inputRef,
  handleInputChange,
  handleKeyDown,
  handleInputFocus,
  handleSendMessage,
  isLoading,
  isTyping,
  handleSkipTyping,
  messagesContainerRef,
  session_id,
  class_id, // kept from the base file changes
  isTTSEnabled,
  setIsTTSEnabled,
  handleVoiceInput,
  isListening
}) {
  const [placeholderText, setPlaceholderText] = useState("Ask me a question! 😊");
  const placeholderOptions = [
    "Ask me a question! 😊",
    "Say something fun! 🤔",
    "What do you want to know? 💫",
    "Let’s get started! 🚀",
    "I'm here to help! 😊",
  ];
  const [showStartMessage, setShowStartMessage] = useState(true);

  // Track if a message has been received so user can skip
  const [messageReceived, setMessageReceived] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderText((prev) => {
        const currentIndex = placeholderOptions.indexOf(prev);
        const nextIndex = (currentIndex + 1) % placeholderOptions.length;
        return placeholderOptions[nextIndex];
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  // Scroll to bottom whenever displayedText changes
  useEffect(() => {
    if (messagesContainerRef?.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [displayedText]);

  // TTS trigger for displayedText and hide the start message once text appears
  useEffect(() => {
    if (isTTSEnabled && displayedText) {
      console.log("TTS Start: ", displayedText);
    }
    if (displayedText) {
      setShowStartMessage(false);
      setMessageReceived(true);
    }
  }, [displayedText, isTTSEnabled]);

  // TTS button now also stops TTS and skips typing
  const handleTTSButtonClick = () => {
    handleStopTTS();
    handleSkipTyping();
    setIsTTSEnabled((prev) => !prev);
  };

  // Fast-forward function to skip TTS and typing
  const handleFastForward = () => {
    handleStopTTS();
    handleSkipTyping();
  };

  const handleInputSendMessage = () => {
    handleSendMessage();
    if (isTTSEnabled && question.trim()) {
      console.log("TTS Start: ", question);
    }
    setMessageReceived(false);
  };

  // avatar look mapping
  const gifMap = {
    felix: {
      typing: "https://i.imgur.com/YwnOomT.gif",
      idle: "https://i.imgur.com/FHXwxRQ.gif",
    },
    ivy: {
      typing: "https://i.imgur.com/schgkuL.gif",
      idle: "https://i.imgur.com/2fvE51N.gif",
    },
    ren: {
      typing: "https://i.imgur.com/7WOXRf5.gif",
      idle: "https://i.imgur.com/ibgqHAY.gif",
    },
    gold: {
      typing: "https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExcGYxdWZmcXMwc2Fmdzh1bDdiN214b2Y2Y2YxZW83NHFpMzBoNjd5OCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/9T5WYktU5aZm1AZLBe/giphy.gif",
      idle: "https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExM3J6MzRsdWUwZHlraWR4eW84bWNtamVucWt0bWZmcTNocDU3Y2w2ciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/vfbdoQLq6XNzWbHU90/giphy.gif",
    },
    // default Mrs. Sanchez
    default: {
      typing: "https://i.imgur.com/uZjHxuK.gif",
      idle: "https://i.imgur.com/RjCt4hf.gif",
    },
  };

  const chosenChar = gifMap[char] || gifMap["default"];

  return (
    <div
      className="fixed inset-0 flex flex-col"
      style={{ backgroundColor: "#73B9AF", margin: 0, padding: 0 }}
    >
      {/* Top Bar for Icons */}
      <div className="flex items-center justify-between p-4 relative z-10">
        <div className="flex items-center gap-8">
          {/* Dynamic TTS Button */}
          <button
            onClick={handleTTSButtonClick}
            className="p-0 transition duration-300"
            aria-label={isTTSEnabled ? "Mute TTS" : "Unmute TTS"}
          >
            {isTTSEnabled ? <SpeakerOnIcon /> : <SpeakerOffIcon />}
          </button>
        </div>
      </div>

      {/* Avatar and Chat Area */}
      <div
        className="absolute inset-0 w-full h-[50%] flex flex-col overflow-hidden"
        style={{ backgroundColor: "#73B9AF" }}
      >
        <div
          className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
          style={{ alignSelf: "center" }}
        >
          <div
            style={{
              height: "100%",
              overflow: "hidden",
              paddingTop: "12px",
              paddingBottom: "12px",
            }}
          >
            <img
              src={isTyping ? chosenChar.typing : chosenChar.idle}
              alt="Avatar Animation"
              style={{
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>

      {/* Bottom Section: Chat Area and Buttons */}
      <div
        className="absolute bottom-0 w-full flex flex-col items-center justify-start p-8 z-10"
        style={{ backgroundColor: "#ECECEC", height: "50%" }}
      >
        {/* Displayed Text */}
        {displayedText && (
          <div
            ref={messagesContainerRef}
            style={{
              maxHeight: "1000px",
              overflowY: "auto",
              width: "100%",
              padding: "8px",
            }}
          >
            <div
              className="p-4 bg-[#73B9AF] text-black text-lg md:text-xl rounded-lg shadow-md break-words cursor-pointer"
              onClick={handleSkipTyping}
              style={{
                fontFamily: "Inter, sans-serif",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "normal",
                color: "#000",
                textAlign: "center",
                background: "#73B9AF",
                backdropFilter: "blur(30px)",
                marginBottom: "16px",
                width: "100%",
              }}
            >
              <span>{displayedText}</span>
            </div>
          </div>
        )}

        {/* Voice Input and Buttons Section */}
        <div
          className="flex flex-col items-center justify-center"
          style={{ width: "100%", marginBottom: "16px" }}
        >
          {showStartMessage ? (
            <div className="flex flex-col items-center">
              {/* This bubble now looks like a received message */}
              <div
                className="p-4 bg-[#73B9AF] text-black text-lg md:text-xl rounded-lg shadow-md break-words cursor-pointer"
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "normal",
                  color: "#000",
                  textAlign: "center",
                  background: "#73B9AF",
                  backdropFilter: "blur(30px)",
                  marginBottom: "16px",
                  width: "100%",
                }}
              >
                <span>
                  Welcome to iykykeducation.com! Whenever you’re ready, let’s dive into your
                  questions and start exploring.
                </span>
              </div>
            </div>
          ) : null}
        </div>

        {/* Input and Send Button */}
        <div
          className="flex items-center w-full relative"
          style={{
            position: "fixed",
            bottom: "32px",
            left: "24px",
            right: "24px",
            maxWidth: "calc(100% - 48px)",
          }}
        >
          <input
            ref={inputRef}
            value={question}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={handleInputFocus}
            className="flex-grow h-12 pl-12 pr-10 rounded-full text-xl focus:outline-none transition duration-300"
            type="text"
            placeholder={placeholderText}
            style={{ color: "#333", backgroundColor: "#FFFFFF" }}
          />
          <button
            onClick={handleVoiceInput}
            className={`absolute left-3 inset-y-0 my-auto h-8 w-8 flex items-center justify-center rounded-full bg-transparent text-black ${
              isListening ? "pulsating" : ""
            }`}
            aria-label="Voice Input"
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon
              icon={isListening ? faMicrophoneSlash : faMicrophone}
              style={{ color: "#000", fontSize: "16px", lineHeight: "16px" }}
              className="fa-fw"
            />
          </button>

          {/* Send / Skip Button */}
          <button
            onClick={
              isLoading
                ? null
                : isTyping && messageReceived
                ? handleFastForward
                : handleInputSendMessage
            }
            className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center justify-center"
            disabled={
              isLoading ||
              (isTyping && !messageReceived) ||
              (!question.trim() && !isTyping)
            }
            aria-label={
              isTyping && messageReceived ? "Skip Typing" : "Send Message"
            }
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : isTyping && messageReceived ? (
              <FontAwesomeIcon icon={faForwardStep} style={{ fontSize: "24px" }} />
            ) : (
              <SendChatIcon />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LMSStyle;
