import React, { useState, useEffect } from "react";
import BubbleChat from "../../chat/components/BubbleChat";
import "../style.css";
import { getAllQueries, verifyURL } from "../apis";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../auth/actions";
import setAuthHeader from "../../../_helpers/setAuthHeader";

const Bot = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isChatbotExist, setIsChatbotExist] = useState(false);
  const [queries, setQueries] = useState([]);
  const [activeChat, setActiveChat] = useState({ queries: [] });
  const dispatch = useDispatch();
  const theme = useSelector((store) => store.setting.isDark);
  const [questionList, setQuestionList] = useState([]);
  const [avatar, setAvatar] = useState("");
  const { tokens } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // const getQueries = async () => {
  //   setIsLoading(true);
  //   await getAllQueries()
  //     .then((res) => {
  //       setQueries(res.data?.chats || []);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("error ", err);
  //       setIsLoading(false);
  //     });
  // };
  // Function to generate a random session ID
  const generateSessionId = () => {
    return 'xxxxxxxxyxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  const getSessionIdFromUrl = () => {
    const sessionId = new URLSearchParams(location.search).get("session_id");
    return sessionId ? sessionId : generateSessionId();
  };

  const getClassIdFromUrl = () => {
    const classId = new URLSearchParams(location.search).get("class_id");
    return classId ? classId : null;
  };
  

  const [session_id, setSessionId] = useState(getSessionIdFromUrl());
  const [class_id, setClassId] = useState(getClassIdFromUrl());

  useEffect(() => {
    if (!location.search.includes("session_id")) {
      navigate(`${location.pathname}?session_id=${session_id}`, { replace: true });
    }
  }, [location, navigate, session_id]);

  useEffect(() => {
    if (!location.search.includes("class_id") && class_id !== null) {
      navigate(`${location.pathname}?class_id=${class_id}`, { replace: true });
    }
  }, [location, navigate, class_id]);
  

  const verifyChatbot = async () => {
    setIsLoading(true);
    const [org, app, email] = tokens?.split("&");
    const chatbotInfo = await verifyURL({ org, app, email });
    if (chatbotInfo) {
      setIsChatbotExist(true);
      setAvatar(chatbotInfo.avatar);
      dispatch(setUser(chatbotInfo));
      setAuthHeader(chatbotInfo.token);
  
      if (session_id) {
        fetchChatHistory(session_id); // Fetch chat history with session_id
      }
  
      if (class_id) {
        console.log("Class ID is present:", class_id);
        // Perform any class_id-specific logic here
      }
    } else {
      setIsChatbotExist(false);
    }
    setIsLoading(false);
  };

  const fetchChatHistory = async (sessionId) => {
    setIsLoading(true);
    try {
      const response = await getAllQueries(sessionId);
      setQueries(response.data?.chats || []);
    } catch (error) {
      console.log("Error fetching chat history", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    verifyChatbot();
  }, [tokens, session_id]);

  if (isLoading) {
    return <div className="coverSpinner"></div>;
  }
  const originColor = theme === true ? "block" : "#171717";

  return isChatbotExist ? (
    <div
      style={{
        backgroundColor: !originColor,
      }}
    >
      <BubbleChat
        setIsMenuOpen={setIsMenuOpen}
        isMenuOpen={isMenuOpen}
        activeChat={activeChat}
        setActiveChat={setActiveChat}
        setQueries={setQueries}
        questionList={questionList}
        setQuestionList={setQuestionList}
        avatar={avatar}
        session_id={session_id} // Pass session_id as a prop
        class_id={class_id || undefined} // Pass class_id only if it's not null
      />
    </div>
  ) : (
    <>Your chatbot URL is failed</>
  );
};

export default Bot;
