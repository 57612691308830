import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faSpinner, 
  faMicrophone, 
  faMicrophoneSlash,
  faForwardStep
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import SpeakerOnIcon from "./icons/SpeakerOnIcon";
import SpeakerOffIcon from "./icons/SpeakerOffIcon";
import ChatHistoryIcon from "./icons/ChatHistoryIcon";
import SendChatIcon from "./icons/SendChatIcon";
import CaptionIcon from "./icons/CaptionIcon";

function LMSQuizStyle({
  char,
  handleStopTTS,
  displayedText,
  initialMessage,
  question,
  inputRef,
  handleInputChange,
  handleKeyDown,
  handleInputFocus,
  handleSendMessage,
  isLoading,
  isTyping,
  handleSkipTyping,
  messagesContainerRef,
  session_id,
  class_id,
  isTTSEnabled,
  setIsTTSEnabled,
  handleVoiceInput,
  isListening,
  speakHoverText, // function to speak text on hover
}) {
  const [placeholderText, setPlaceholderText] = useState("Ask me a question! 😊");
  const placeholderOptions = [
    "Ask me a question! 😊",
    "Say something fun! 🤔",
    "What do you want to know? 💫",
    "Let’s get started! 🚀",
    "I'm here to help! 😊",
  ];

  const [showStartMessage, setShowStartMessage] = useState(true);
  const [messageReceived, setMessageReceived] = useState(false);
  const [selectedOptionKey, setSelectedOptionKey] = useState(null);
  const [startButtonSelected, setStartButtonSelected] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderText((prev) => {
        const currentIndex = placeholderOptions.indexOf(prev);
        const nextIndex = (currentIndex + 1) % placeholderOptions.length;
        return placeholderOptions[nextIndex];
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (
      displayedText &&
      !displayedText.includes(
        "Message from System: start the user over from the beginning"
      )
    ) {
      setShowStartMessage(false);
      setMessageReceived(true);
      setSelectedOptionKey(null);
    }
  }, [displayedText, isTTSEnabled]);

  // NEW: Scroll to bottom when a new message appears
  useEffect(() => {
    if (messagesContainerRef?.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [displayedText]);

  // Updated TTS button to also stop TTS and skip typing
  const handleTTSButtonClick = () => {
    handleStopTTS();
    handleSkipTyping();
    setIsTTSEnabled((prev) => !prev);
  };

  // Use alphabetical splitting like the original quiz style
  const extractOptions = (text) => {
    const options = {};
    const pattern = /([A-Z])\)\s*(.*?)(?=(?:[A-Z]\)|$))/gs;
    let match;
    while ((match = pattern.exec(text)) !== null) {
      const letter = match[1];
      let optionText = match[2].trim();
      optionText = optionText.replace(/[?,]$/g, "").trim();
      options[letter] = optionText;
    }
    const modifiedText = text.replace(pattern, "").trim();
    return { options, modifiedText };
  };

  const handleFastForward = () => {
    handleStopTTS();
    handleSkipTyping();
  };

  const { options, modifiedText } = extractOptions(displayedText || "");

  const handleQuizButtonClick = (value) => {
    if (!isLoading && !isTyping) {
      handleInputChange({ target: { value } });
      handleSendMessage(value);
      if (isTTSEnabled) console.log("TTS Start: ", value);
      setMessageReceived(false);
    }
  };

  const handleInputSendMessage = () => {
    handleSendMessage();
    if (isTTSEnabled) console.log("TTS Start: ", question);
    setMessageReceived(false);
  };

  // MODIFIED: set the button to selected on click
  const handleStartButtonClick = () => {
    setStartButtonSelected(true);
    handleInputChange({ target: { value: "start" } });
    handleSendMessage(
      "Message from System: start the user over from the beginning as if they are just now speaking to you for the first time."
    );
  };

  // avatar look mapping
  const gifMap = {
    felix: {
      typing: "https://i.imgur.com/YwnOomT.gif",
      idle: "https://i.imgur.com/FHXwxRQ.gif",
    },
    ivy: {
      typing: "https://i.imgur.com/schgkuL.gif",
      idle: "https://i.imgur.com/2fvE51N.gif",
    },
    ren: {
      typing: "https://i.imgur.com/7WOXRf5.gif",
      idle: "https://i.imgur.com/ibgqHAY.gif",
    },
    gold: {
      typing: "https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExcGYxdWZmcXMwc2Fmdzh1bDdiN214b2Y2Y2YxZW83NHFpMzBoNjd5OCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/9T5WYktU5aZm1AZLBe/giphy.gif",
      idle: "https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExM3J6MzRsdWUwZHlraWR4eW84bWNtamVucWt0bWZmcTNocDU3Y2w2ciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/vfbdoQLq6XNzWbHU90/giphy.gif",
    },
    // default Mrs. Sanchez
    default: {
      typing: "https://i.imgur.com/uZjHxuK.gif",
      idle: "https://i.imgur.com/RjCt4hf.gif",
    },
  };

  const chosenChar = gifMap[char] || gifMap["default"];

  return (
    <div
      className="fixed inset-0 flex flex-col"
      style={{ backgroundColor: "#73B9AF", margin: 0, padding: 0 }}
    >
      {/* Top Bar for Icons */}
      <div className="flex items-center justify-between p-4 relative z-10">
        <div className="flex items-center gap-8">
          {/* Dynamic TTS Button */}
          <button
            onClick={handleTTSButtonClick}
            className="p-0 transition duration-300"
            aria-label={isTTSEnabled ? "Mute TTS" : "Unmute TTS"}
          >
            {isTTSEnabled ? <SpeakerOnIcon /> : <SpeakerOffIcon />}
          </button>
        </div>
      </div>

      {/* Avatar and Chat Area */}
      <div
        className="absolute inset-0 w-full h-[50%] flex flex-col overflow-hidden"
        style={{ backgroundColor: "#73B9AF" }}
      >
        <div
          className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
          style={{ alignSelf: "center" }}
        >
          <div
            style={{
              height: "100%",
              overflow: "hidden",
              paddingTop: "12px",
              paddingBottom: "12px",
            }}
          >
            <img
              src={isTyping ? chosenChar.typing : chosenChar.idle}
              alt="Avatar Animation"
              style={{
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>

      {/* Bottom Section: Chat Area and Buttons */}
      <div
        className="absolute bottom-0 w-full flex flex-col items-center justify-start p-8 z-10"
        style={{ backgroundColor: "#ECECEC", height: "50%" }}
      >
        <div
          ref={messagesContainerRef}
          style={{
            maxHeight: "1000px",
            overflowY: "auto",
            width: "100%",
            padding: "8px",
          }}
        >
          {/* Displayed Text */}
          {displayedText && (
            <div
              className="p-4 bg-[#73B9AF] text-black text-lg md:text-xl rounded-lg shadow-md break-words cursor-pointer"
              style={{
                fontFamily: "Inter, sans-serif",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "normal",
                color: "#000",
                textAlign: "center",
                background: "#73B9AF",
                backdropFilter: "blur(30px)",
                marginBottom: "16px",
                width: "100%",
              }}
              onMouseEnter={() => {
                if (!isTyping && speakHoverText) {
                  speakHoverText(modifiedText);
                }
              }}
              //onClick={handleSkipTyping}
            >
              <span>{modifiedText}</span>
            </div>
          )}

          {/* Voice Input and Buttons Section */}
          <div
            className="flex flex-col items-center justify-center"
            style={{ width: "100%", marginBottom: "16px" }}
          >
            {showStartMessage ? (
              <div className="flex flex-col items-center">
                <div
                  className="p-4 bg-[#73B9AF] text-black text-lg md:text-xl rounded-lg shadow-md break-words cursor-pointer"
                  style={{
                    fontFamily: "Inter, sans-serif",
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "normal",
                    color: "#000",
                    textAlign: "center",
                    background: "#73B9AF",
                    backdropFilter: "blur(30px)",
                    marginBottom: "16px",
                    width: "100%",
                  }}
                >
                  <p>
                    Press the "Start" button to begin chatting! Once you start, you can
                    type your questions or click the buttons that pop up to choose an
                    answer. Click the speaker button to mute or hear the bot.
                  </p>
                </div>
                <button
                  onClick={handleStartButtonClick}
                  className={`${
                    startButtonSelected
                      ? "bg-[#73B9AF]"
                      : "bg-white hover:bg-[#AFD3CD] active:bg-[#73B9AF]"
                  }`}
                  style={{
                    margin: "0 24px",
                    minHeight: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px",
                    fontWeight: "500",
                    fontSize: "20px",
                    width: "calc(100% - 48px)",
                  }}
                >
                  Start
                </button>
              </div>
            ) : (
              Object.keys(options).length > 0 && (
                <div
                  className="flex flex-wrap w-full"
                  style={{
                    justifyContent: "center",
                    alignItems: "stretch",
                    marginBottom: "16px",
                  }}
                >
                  {Object.entries(options).map(([key, value]) => (
                    <button
                      key={key}
                      onClick={() => {
                        setSelectedOptionKey(key);
                        handleQuizButtonClick(value);
                      }}
                      onMouseEnter={() => {
                        if (!isTyping && speakHoverText) {
                          speakHoverText(value);
                        }
                      }}
                      className={`${
                        selectedOptionKey === key
                          ? "bg-[#73B9AF]"
                          : "bg-white hover:bg-[#AFD3CD] active:bg-[#73B9AF]"
                      }`}
                      style={{
                        flex: "1 0 30%",
                        margin: "8px",
                        minHeight: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "8px",
                        fontWeight: "500",
                        fontSize: "20px",
                      }}
                    >
                      {`${key}: ${value}`}
                    </button>
                  ))}
                </div>
              )
            )}
          </div>
        </div>

        {/* Input and Send Button */}
        <div
          className="flex items-center w-full relative"
          style={{
            position: "fixed",
            bottom: "32px",
            left: "24px",
            right: "24px",
            maxWidth: "calc(100% - 48px)",
          }}
        >
          <input
            ref={inputRef}
            value={question}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={handleInputFocus}
            className="flex-grow h-12 pl-12 pr-10 rounded-full text-xl focus:outline-none transition duration-300"
            type="text"
            placeholder={placeholderText}
            disabled={showStartMessage}
            style={{ color: "#333", backgroundColor: "#FFFFFF" }}
          />
          {/* Voice Input Button inside the input box */}
          <button
            onClick={handleVoiceInput}
            className={`absolute left-3 inset-y-0 my-auto h-8 w-8 flex items-center justify-center rounded-full bg-transparent text-black ${
              isListening ? "pulsating" : ""
            }`}
            aria-label="Voice Input"
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon
              icon={isListening ? faMicrophoneSlash : faMicrophone}
              style={{ color: "#000", fontSize: "16px", lineHeight: "16px" }}
              className="fa-fw"
            />
          </button>

          {/* Send / Skip Button */}
          <button
            onClick={
              isLoading
                ? null
                : isTyping && messageReceived
                ? handleFastForward
                : handleInputSendMessage
            }
            className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center justify-center"
            disabled={
              isLoading ||
              (isTyping && !messageReceived) ||
              (!question.trim() && !isTyping) ||
              showStartMessage
            }
            aria-label={isTyping && messageReceived ? "Skip Typing" : "Send Message"}
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : isTyping && messageReceived ? (
              <FontAwesomeIcon icon={faForwardStep} style={{ fontSize: "24px" }} />
            ) : (
              <SendChatIcon />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LMSQuizStyle;
