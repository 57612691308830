import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { generateBubbleChat } from "../apis";
import "../style.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LMSStyle from "./LMSStyle";
import HeygenStyle from "./HeygenStyle";
import DefaultStyle from "./DefaultStyle";
import LMSQuizStyle from "./LMSQuizStyle";
import LMSActivityStyle from "./LMSActivityStyle"; // Import LMSActivityStyle component
import * as sdk from "microsoft-cognitiveservices-speech-sdk";

// HeyGen TTS and avatar streaming constants
const HEYGEN_API_KEY = 'OTQ2MzIzMmNkM2U1NDAzODk2OGRhNTU3MWY1NDAzYTMtMTczNDExMDc3OQ==';
const HEYGEN_SERVER_URL = 'https://api.heygen.com';
const HEYGEN_AVATAR_ID = 'ef08039a41354ed5a20565db899373f3';
const HEYGEN_VOICE_ID = '1bd001e7e50f421d891986aad5158bc8';

const SPEECH_KEY = '4qJDg7p3eSOWexOkXWFz8b1Zh2uSTmlN8X3iZu9ZH6YTiubpaBmTJQQJ99BCACYeBjFXJ3w3AAAYACOGfDLx';
const SPEECH_REGION = 'eastus';

function BubbleChat({
  activeChat,
  setActiveChat,
  setQueries,
  questionList,
  setQuestionList,
  avatar,
  session_id,
  class_id,
}) {
  const serverAddress = process.env.REACT_APP_URL;
  const activeModel = useSelector((store) => store.auth.activeModel);
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesContainerRef = useRef(null);
  const timestampedQuestions = useRef([]);
  const location = useLocation();
  const [initialMessage, setInitialMessage] = useState("How can I help you?");
  const [isLMSStyle, setIsLMSStyle] = useState(false);
  const [isHeygenStyle, setIsHeygenStyle] = useState(false);
  const [isLMSQuizStyle, setIsLMSQuizStyle] = useState(false);
  const [isLMSActivityStyle, setIsLMSActivityStyle] = useState(false);
  const [isLMSQuizStyle2, setIsLMSQuizStyle2] = useState(false);
  const [isLMSQuizStyle3, setIsLMSQuizStyle3] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  // Typewriter effect states
  const [displayedText, setDisplayedText] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [skipTyping, setSkipTyping] = useState(false);

  // TTS enabled or not
  const [isTTSEnabled, setIsTTSEnabled] = useState(true);

  // Delay (ms) between typed characters (or words) – adjusted after TTS
  const [typingDelay, setTypingDelay] = useState(null);

  // Char param from URL
  const [charParam, setCharParam] = useState("");

  // Speech references
  const speechConfig = useRef(null);
  const audioConfig = useRef(null);
  const recognizer = useRef(null);
  const synthesizer = useRef(null);
  const player = useRef(null);

  // For voice-to-text (speech recognition)
  const [isListening, setIsListening] = useState(false);

  // **NEW** peer connection for HeyGen
  const peerConnection = useRef(null);
  const sessionInfo = useRef(null); // Holds the session info for streaming

  // **NEW** reference to pause repeated TTS calls on hover
  const ttsHoverPauseRef = useRef(false);

  // **NEW** store which texts have already been spoken
  const spokenHoverTextsRef = useRef(new Set());

  const activeSynthRef = useRef(null);

  // Add these state/ref variables to your component
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioQueue = useRef([]);
  const isProcessingAudio = useRef(false);


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const startingMessage = params.get("starting_message");
    const styleParam = params.get("style");
    const charFromUrl = params.get("char") || "";

    setCharParam(charFromUrl);

    if (startingMessage) {
      setInitialMessage(startingMessage);
      // Clear it immediately after being set
      setTimeout(() => {
        setInitialMessage("");
      }, 0);
    }

    // Style checks
    if (styleParam === "lms") {
      setIsLMSStyle(true);
      setIsOpen(true);
    }
    if (styleParam === "heygen") {
      setIsHeygenStyle(true);
      setIsOpen(true);
      initializeSession(); // Start HeyGen avatar session
    }
    if (styleParam === "lmsquiz") {
      setIsLMSQuizStyle(true);
      setIsOpen(true);
    }
    if (styleParam === "lmsquiz2") {
      setIsLMSQuizStyle2(true);
      setIsOpen(true);
    }
    if (styleParam === "lmsquiz3") {
      setIsLMSQuizStyle3(true);
      setIsOpen(true);
    }
    if (styleParam === "lmsactivity") {
      setIsLMSActivityStyle(true);
      setIsOpen(true);
    }

    // Initialize speech config
    speechConfig.current = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
    speechConfig.current.speechRecognitionLanguage = "en-US";

    // Initialize audio config, recognizer, synthesizer
    audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();
    recognizer.current = new sdk.SpeechRecognizer(speechConfig.current, audioConfig.current);
    synthesizer.current = new sdk.SpeechSynthesizer(speechConfig.current, null);

    // Recognizer events
    recognizer.current.recognized = (s, e) => processRecognizedTranscript(e);
    recognizer.current.recognizing = (s, e) => processRecognizingTranscript(e);

    return () => {
      recognizer.current.stopContinuousRecognitionAsync(() => {
        setIsListening(false);
      });
    };
  }, [location]);

  const processRecognizedTranscript = (event) => {
    const result = event.result;
    if (result.reason === sdk.ResultReason.RecognizedSpeech) {
      const transcript = result.text.trim();
      setQuestion((prevQuestion) =>
        prevQuestion ? `${prevQuestion} ${transcript}` : transcript
      );
    }
  };

  const processRecognizingTranscript = (event) => {
    const result = event.result;
    if (result.reason === sdk.ResultReason.RecognizingSpeech) {
      const transcript = result.text.trim();
      // If partial accumulation is wanted, uncomment:
      // setQuestion((prevQuestion) =>
      //   prevQuestion ? `${prevQuestion} ${transcript}` : transcript
      // );
    }
  };

  const handleVoiceInput = () => {
    if (isListening) {
      recognizer.current.stopContinuousRecognitionAsync(() => {
        console.log("Speech recognition stopped.");
        setIsListening(false);
      });
    } else {
      recognizer.current.startContinuousRecognitionAsync(() => {
        console.log("Speech recognition started.");
        setIsListening(true);
      });
    }
  };

  const stopSpeechRecognition = (reason) => {
    if (isListening) {
      recognizer.current.stopContinuousRecognitionAsync(() => {
        console.log(`Speech recognition stopped due to ${reason}.`);
        setIsListening(false);
      });
    }
  };

  // ---------------------
  // HeyGen session
  // ---------------------
  const initializeSession = async () => {
    try {
      console.log("Initializing session...");
      sessionInfo.current = await createNewSession();
      await startAndDisplaySession();
    } catch (error) {
      console.error("Error initializing HeyGen session:", error);
    }
  };

  const createNewSession = async () => {
    const response = await fetch(`${HEYGEN_SERVER_URL}/v1/streaming.new`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': HEYGEN_API_KEY,
      },
      body: JSON.stringify({
        quality: 'low',
        avatar_name: HEYGEN_AVATAR_ID,
        voice: { voice_id: HEYGEN_VOICE_ID },
      }),
    });

    if (response.status !== 200) {
      throw new Error('Failed to create session');
    }
    const data = await response.json();
    return data.data;
  };

  const startAndDisplaySession = async () => {
    if (!sessionInfo.current) {
      return;
    }

    const { sdp: serverSdp, ice_servers2: iceServers } = sessionInfo.current;
    peerConnection.current = new RTCPeerConnection({ iceServers });

    peerConnection.current.ontrack = (event) => {
      if (event.track.kind === 'audio' || event.track.kind === 'video') {
        const mediaElement = document.querySelector('#mediaElement');
        if (mediaElement) {
          mediaElement.srcObject = event.streams[0];
        }
      }
    };

    peerConnection.current.onicecandidate = ({ candidate }) => {
      if (candidate) {
        handleICE(sessionInfo.current.session_id, candidate.toJSON());
      }
    };

    const remoteDescription = new RTCSessionDescription(serverSdp);
    await peerConnection.current.setRemoteDescription(remoteDescription);

    const localDescription = await peerConnection.current.createAnswer();
    await peerConnection.current.setLocalDescription(localDescription);

    await startSession(sessionInfo.current.session_id, localDescription);
  };

  const handleICE = async (session_id, candidate) => {
    const response = await fetch(`${HEYGEN_SERVER_URL}/v1/streaming.ice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': HEYGEN_API_KEY,
      },
      body: JSON.stringify({ session_id, candidate }),
    });

    if (response.status !== 200) {
      throw new Error('Failed to handle ICE');
    }
  };

  const startSession = async (session_id, sdp) => {
    const response = await fetch(`${HEYGEN_SERVER_URL}/v1/streaming.start`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': HEYGEN_API_KEY,
      },
      body: JSON.stringify({ session_id, sdp }),
    });

    if (response.status !== 200) {
      throw new Error('Failed to start session');
    }
  };

  // HeyGen TTS
  const heyGenTTS = async (text) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${HEYGEN_SERVER_URL}/v1/streaming.task`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': HEYGEN_API_KEY,
          },
          body: JSON.stringify({
            session_id: sessionInfo.current.session_id,
            text: text,
          }),
        });

        if (response.status !== 200) {
          reject(new Error('Failed to process TTS'));
          return;
        }
        // No direct "done" callback from HeyGen, but we assume success quickly
        setTimeout(() => {
          resolve();
        }, 1000); // small delay
      } catch (error) {
        console.error('Error in HeyGen TTS:', error);
        reject(error);
      }
    });
  };

  const textToSpeech = async (text) => {
    handleStopTTS();
    return new Promise((resolve, reject) => {
      if (!isTTSEnabled) {
        resolve();
        return;
      }
  
      // Filter out disallowed characters
      let filtered_text;
      if (isLMSActivityStyle) {
        // Only remove numbering for LMS Activity style
        filtered_text = text.replace(/#/g, "").replace(/\d+\)\s*/g, "");
      } else {
        // Original behavior for other styles
        filtered_text = text.replace(/#/g, "");
      }
  
      // Speaker destination
      player.current = new sdk.SpeakerAudioDestination();
  
      // onAudioEnd => fires ONLY once playback ends
      player.current.onAudioEnd = () => {
        console.log("Audio fully ended. TTS is done playing.");
        setIsTyping(false);
        setIsAudioPlaying(false);
        resolve();
      };
  
      // Build the AudioConfig for Azure TTS using the speaker output
      const audioOutput = sdk.AudioConfig.fromSpeakerOutput(player.current);
  
      // Create a synthesizer and store it in a ref for stopping later
      const localSynthesizer = new sdk.SpeechSynthesizer(
        speechConfig.current,
        audioOutput
      );
      
      // Important: store the active synthesizer reference for later stopping
      activeSynthRef.current = localSynthesizer;
  
      // Construct the SSML based on character parameter
      let ssml;
      if (isLMSQuizStyle2) {
        ssml = `
          <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
                 xmlns:mstts="https://www.w3.org/2001/mstts"
                 xml:lang="en-US">
            <voice name="en-US-SerenaMultilingualNeural">
              <mstts:express-as style="sad">
                ${filtered_text}
              </mstts:express-as>
            </voice>
          </speak>
        `;
      } else if (charParam === "ren") {
        ssml = `
          <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
                 xmlns:mstts="https://www.w3.org/2001/mstts"
                 xml:lang="en-US">
            <voice name="en-US-BrianMultilingualNeural">
              <mstts:express-as style="happy">
                <prosody pitch="high">
                  ${filtered_text}
                </prosody>
              </mstts:express-as>
            </voice>
          </speak>
        `;
      } else if (charParam === "ivy") {
        ssml = `
          <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
                 xmlns:mstts="https://www.w3.org/2001/mstts"
                 xml:lang="en-US">
            <voice name="en-US-AshleyNeural">
              <mstts:express-as style="happy">
                <prosody pitch="high">
                  ${filtered_text}
                </prosody>
              </mstts:express-as>
            </voice>
          </speak>
        `;
      } else if (charParam === "felix") {
        ssml = 
          `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
                 xmlns:mstts="https://www.w3.org/2001/mstts"
                 xml:lang="en-US">
            <voice name="en-US-BrianNeural">
              <mstts:express-as style="happy">
                <prosody pitch="high">
                  ${filtered_text}
                </prosody>
              </mstts:express-as>
            </voice>
          </speak>`;
      }
       else if (charParam === "gold") {
        ssml = `
          <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
                 xmlns:mstts="https://www.w3.org/2001/mstts"
                 xml:lang="en-US">
            <voice name="en-US-Ava:DragonHDLatestNeural">
              <mstts:express-as style="excited">
                <prosody rate="1.05">
                  ${filtered_text}
                </prosody>
              </mstts:express-as>
            </voice>
          </speak>
        `;
      } else {
        // Default voice
        ssml = `
          <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
                 xmlns:mstts="https://www.w3.org/2001/mstts"
                 xml:lang="en-US">
            <voice name="en-US-JennyNeural">
              ${filtered_text}
            </voice>
          </speak>
        `;
      }
  
      // Speak the SSML
      localSynthesizer.speakSsmlAsync(
        ssml,
        (result) => {
          if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
            console.log("Azure TTS successfully synthesized audio. Now playing...");
            
            // Calculate your typing delay if needed
            const audioData = result.audioData;
            const sampleRate = 24000; // typical 24k for Azure
            const durationInSeconds = audioData.byteLength / (sampleRate * 1 * 2); // mono, 16-bit
  
            if (isLMSQuizStyle3) {
              // Word-by-word
              const words = filtered_text.split(" ");
              const wordCount = words.length;
              const WORD_DELAY_MULTIPLIER = 1.2;
              const calculatedTypingDelay =
                ((durationInSeconds * 1000) / wordCount) * WORD_DELAY_MULTIPLIER;
              setTypingDelay(calculatedTypingDelay);
            } else {
              // Character-by-character
              const calculatedTypingDelay =
                (durationInSeconds * 1000) / filtered_text.length;
              setTypingDelay(calculatedTypingDelay * 8);
            }
          } else if (result.reason === sdk.ResultReason.Canceled) {
            console.error("Azure TTS Canceled:", result.errorDetails);
            localSynthesizer.close();
            reject(new Error(result.errorDetails));
            return;
          }
        },
        (err) => {
          console.error("Error during TTS synthesis:", err);
          localSynthesizer.close();
          reject(err);
        }
      );
    });
  };
  

  // ---------------------
  // Typewriter effect
  // ---------------------
  useEffect(() => {
    const lastMessageIndex = activeChat.queries.length - 1;
    const lastMessage = activeChat.queries[lastMessageIndex]?.solution || "";

    // If no message or user has skipped
    if (!lastMessage || skipTyping) {
      setIsTyping(false);
      return;
    }

    // If typing has finished
    if (typingIndex >= lastMessage.length) {
      setIsTyping(false);
      return;
    }

    // If typingDelay is not yet set (it comes after TTS)
    if (typingDelay === null) {
      return;
    }

    // Start typing
    setIsTyping(true);

    // Word-by-word for lmsquiz3
    if (isLMSQuizStyle3) {
      // Wait a bit longer per word
      const nextSpace = lastMessage.indexOf(" ", typingIndex);
      const boundary = nextSpace === -1 ? lastMessage.length : nextSpace;
      const timer = setTimeout(() => {
        setDisplayedText(lastMessage.slice(0, boundary));
        setTypingIndex(boundary + 1);
      }, typingDelay * 7);

      return () => clearTimeout(timer);
    } else {
      // Character-by-character
      const timer = setTimeout(() => {
        setDisplayedText(lastMessage.slice(0, typingIndex + 1));
        setTypingIndex(typingIndex + 1);
      }, typingDelay);

      return () => clearTimeout(timer);
    }
  }, [
    activeChat.queries,
    typingIndex,
    skipTyping,
    typingDelay,
    isLMSQuizStyle3,
  ]);

  const handleSkipTyping = () => {
    handleStopTTS();
    const lastMessageIndex = activeChat.queries.length - 1;
    const lastMessage = activeChat.queries[lastMessageIndex]?.solution || "";

    setDisplayedText(lastMessage);
    setTypingIndex(lastMessage.length);
    setIsTyping(false);
    setSkipTyping(true);
  };

  const handleInputChange = (input) => {
    const newValue = typeof input === "string" ? input : input?.target?.value || "";
    setQuestion(newValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = async (msg = question) => {
    handleStopTTS();
    const messageToSend = typeof msg === "string" ? msg : String(msg);
    if (!messageToSend || isLoading || isTyping) {
      return;
    }

    const timestamp = new Date().toLocaleString();
    const messageWithTimestamp = `${messageToSend} (Sent at: ${timestamp})`;

    setQuestionList([...questionList, messageToSend]);
    timestampedQuestions.current.push(messageWithTimestamp);

    let payload = {
      question: messageWithTimestamp,
      modelId: activeModel,
      session_id,
      class_id,
      isBubble: true,
    };

    if (!activeChat.id) {
      const splitQues = messageToSend.split(" ");
      payload.isNew = true;
      payload.title = splitQues[0] + " " + (splitQues[1] || "") + " " + (splitQues[2] || "");
    } else {
      payload.id = activeChat.id;
    }

    // If it's an LMS quiz style or LMS activity style
    if (isLMSQuizStyle) {
      payload.isLMSQuiz = true;
    }
    if (isLMSActivityStyle) {
      payload.isLMSActivity = true;
    }

    setIsLoading(true);
    setQuestion("");

    try {
      const res = await generateBubbleChat(payload);
      setQueries(res.data.chats);

      const oldActiveChat = res.data.chats.find(
        (chat) => chat.id === activeChat.id
      );
      const chat = oldActiveChat
        ? oldActiveChat
        : res.data.chats[res.data.chats.length - 1];
      setActiveChat(chat);

      setIsLoading(false);

      // Reset typewriter states
      setTypingIndex(0);
      setDisplayedText("");
      setSkipTyping(false);
      setTypingDelay(null); // Will be set after TTS

      if (!isTTSEnabled) {
        // If TTS is disabled, start typing immediately at a default speed
        const defaultTypingDelay = 60; // Fast default typing speed in milliseconds
        setTypingDelay(defaultTypingDelay);
        setIsTyping(true);
        
        // Get the message ready for typing
        const lastResponseMessage = chat.queries[chat.queries.length - 1]?.solution || "";
        // The typewriter effect will now begin with the default speed
        return; // Skip the TTS
      }

      // ----
      // Now we do TTS first, then type
      // ----
      const lastResponseMessage =
        chat.queries[chat.queries.length - 1]?.solution || "";

        if (lastResponseMessage.trim() !== "") {
          if (isHeygenStyle) {
            // Wait for HeyGen TTS to resolve
            await heyGenTTS(lastResponseMessage);
          } else {
            // Wait for Azure TTS to resolve, but skip if not received within 3000ms
            await Promise.race([
              textToSpeech(lastResponseMessage),
              new Promise(resolve => setTimeout(() => {
                console.warn("Azure TTS not received, skipping typing.");
                resolve();
              }, 3000)) // adjust timeout as needed
            ]);
          }
          // Once TTS resolves (or times out), the typewriter effect will begin
        }
    } catch (err) {
      toast("Something went wrong. Please check retrain model status", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false);
    }
  };

  const handleBubbleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleInputFocus = () => {
    stopSpeechRecognition("input focus");
  };

  // 1. Improved handleStopTTS function to completely stop any playing audio
  const handleStopTTS = () => {
    // Stop the audio player if it exists
    if (player.current) {
      try {
        player.current.pause();
      } catch (e) {
        console.error("Error pausing player:", e);
      }
    }
  
    // Stop any active synthesizer
    if (activeSynthRef.current) {
      try {
        // For older SDKs that use stopSynthesisAsync
        if (typeof activeSynthRef.current.stopSynthesisAsync === "function") {
          activeSynthRef.current.stopSynthesisAsync(
            () => console.log("Stopped TTS synthesis immediately."),
            (err) => console.error("Error stopping TTS synthesis:", err)
          );
        }
        
        // Close the synthesizer to release resources
        activeSynthRef.current.close();
        activeSynthRef.current = null;
      } catch (e) {
        console.error("Error closing synthesizer:", e);
      }
    }
  
    setIsTyping(false);
  };
  

// 2. Audio queue processing function to manage sequential playback
const processAudioQueue = async () => {
  if (isProcessingAudio.current || audioQueue.current.length === 0) {
    return;
  }
  
  isProcessingAudio.current = true;
  setIsAudioPlaying(true);
  
  const nextAudio = audioQueue.current.shift();
  try {
    // Stop any existing audio first
    handleStopTTS();
    
    // Play the next audio
    await textToSpeech(nextAudio.text);
  } catch (error) {
    console.error("Error processing audio in queue:", error);
  } finally {
    isProcessingAudio.current = false;
    setIsAudioPlaying(false);
    
    // Process the next item in queue if any
    if (audioQueue.current.length > 0) {
      processAudioQueue();
    }
  }
};

// 3. Function to add audio to the queue
const queueAudio = (text, priority = false) => {
  if (!isTTSEnabled || !text || text.trim() === "") {
    return;
  }
  
  // If priority, clear the queue and stop current audio
  if (priority) {
    audioQueue.current = [];
    handleStopTTS();
  }
  
  // Add to queue
  audioQueue.current.push({ text });
  
  // Start processing if not already
  if (!isProcessingAudio.current) {
    processAudioQueue();
  }
};

  // ---------------------------
  // Updated speakHoverText
  // ---------------------------
  const speakHoverText = (hoverText) => {
    // Skip if: gold style, TTS not enabled, or no text
    if (charParam === "gold" || !isTTSEnabled) return;
    if (!hoverText || hoverText.trim() === "") return;

    handleStopTTS();
  
    // Important change: Remove the check for previously spoken texts
    // This allows the same text to be spoken again if hovered multiple times
    // spokenHoverTextsRef.current.has(hoverText) check is removed
  
    // If currently typing out a main message, don't interrupt with hover text
    if (isTyping) return;
  
    // If not in pause period (to prevent rapid-fire TTS)
    if (!ttsHoverPauseRef.current) {
      // Always stop any current TTS before starting a new one
      handleStopTTS();
      
      // Start the new TTS
      textToSpeech(hoverText);
      
      // Add to spoken texts set (for logging purposes if needed)
      spokenHoverTextsRef.current.add(hoverText);
      
      // Set pause period (to prevent TTS spam)
      ttsHoverPauseRef.current = true;
      setTimeout(() => {
        ttsHoverPauseRef.current = false;
      }, 800); // Reduced to 800ms to make it more responsive but still prevent spam
    }
  };
  

  // Common props for child style components
  const getCommonProps = () => ({
    displayedText,
    initialMessage,
    question,
    handleVoiceInput,
    isListening,
    inputRef: null, // just leaving as null or your original ref if needed
    handleInputChange,
    handleKeyDown,
    handleInputFocus,
    handleSendMessage,
    isLoading,
    isTyping,
    handleSkipTyping,
    messagesContainerRef,
    setQuestion,
  });

  // Render based on style
  if (isLMSStyle) {
    return (
      <LMSStyle
        {...getCommonProps()}
        session_id={session_id}
        class_id={class_id}
        isTTSEnabled={isTTSEnabled}
        setIsTTSEnabled={setIsTTSEnabled}
        handleStopTTS={handleStopTTS}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        avatar={avatar}
        serverAddress={serverAddress}
        char={charParam}
      />
    );
  } else if (isHeygenStyle) {
    return (
      <HeygenStyle
        {...getCommonProps()}
      />
    );
  } else if (isLMSQuizStyle) {
    return (
      <LMSQuizStyle
        {...getCommonProps()}
        session_id={session_id}
        class_id={class_id}
        isTTSEnabled={isTTSEnabled}
        setIsTTSEnabled={setIsTTSEnabled}
        handleStopTTS={handleStopTTS}
        char={charParam}
        speakHoverText={speakHoverText}
      />
    );
  } else if (isLMSActivityStyle) {
    return (
      <LMSActivityStyle
        {...getCommonProps()}
        speakHoverText={speakHoverText}
        session_id={session_id}
        class_id={class_id}
        isTTSEnabled={isTTSEnabled}
        setIsTTSEnabled={setIsTTSEnabled}
        handleStopTTS={handleStopTTS}
        char={charParam}
      />
    );
  } else {
    // Default style
    return (
      <DefaultStyle
        isOpen={isOpen}
        avatar={avatar}
        serverAddress={serverAddress}
        questionList={questionList}
        activeChat={activeChat}
        timestampedQuestions={timestampedQuestions}
        isLoading={isLoading}
        question={question}
        handleInputChange={handleInputChange}
        handleKeyDown={handleKeyDown}
        handleSendMessage={handleSendMessage}
        handleBubbleClick={handleBubbleClick}
        messagesContainerRef={messagesContainerRef}
      />
    );
  }
}

export default BubbleChat;
